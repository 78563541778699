//https://github.com/vandit-bera/React-Routing
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ErrorPage from './component/ErrorPage';
import App from './App';
import Legal from "./component/Legal";
import { nanoid } from "nanoid";
import Contact from "./component/Contact";
import SearchPage from "./component/SearchPage";
import Loader from "./component/Loader";
import Home from "./component/Home";
import Page from "./component/Page";

import AddComponent from "./component/AddComponent";
import TaxonomyMore from "./component/TaxonomyMore";
import TaxonomyMain from "./component/TaxonomyMain";
import TaxonomyMainScores from "./component/TaxonomyMainScores";

import EdBlockTax from './component/EdBlockTax';

import SingleStory from "./component/SingleStory";

import reportWebVitals from './reportWebVitals';
import { createBrowserRouter,RouterProvider, useLocation } from 'react-router-dom';
import ThemeProvider from './context/ThemeProvider';
import fetchStoryWithRetry from './component/functions/fetchStoryWithRetry';
import getFromTax from './component/functions/getFromTax';

const edHost = process.env.REACT_APP_HOST_URL



const fetchItemsTaxonomyMain = async ({request,params}) => {
var urlChunk = request.url.split('/');//['http:', '', 'localhost:3000', 'news', 'dressage']

const { slug, tax } = params;
var _tax = getFromTax(urlChunk[3],urlChunk[3],'id');

console.log(slug);
const url = `${edHost}/api/ed/story/cat/${_tax}/0/0`;

try {
  return await fetchStoryWithRetry(url,slug);
} catch (error) {
  console.error('Failed to fetch the story after retries:', error);
  return null; // Handle the error appropriately
}
};
const fetchItemsScoresMain = async ({request,params}) => {
/*
var urlChunk = request.url.split('/');//['http:', '', 'localhost:3000', 'news', 'dressage']
const { tax } = params;
*/
const { slug } = params;
var _tax = 22;//getFromTax(urlChunk[3],urlChunk[3],'id');

//console.log(_tax);
/* const url = `${edHost}/rest/ed/story/cat/${_tax}/0/0`; */
const url = `${edHost}/api/ed/tax/scores/0/0`;

try {
  return await fetchStoryWithRetry(url,slug);
} catch (error) {
  console.error('Failed to fetch the story after retries:', error);
  return null; // Handle the error appropriately
}
};


  const fetchItemsTaxonomy = async ({request,params}) => {
  var urlChunk = request.url.split('/');//['http:', '', 'localhost:3000', 'news', 'dressage']

  const { slug,tax } = params;
  var _tax = getFromTax(urlChunk[3],tax,'id');
//console.log(_tax);
  const url = `${edHost}/api/ed/story/cat/${_tax}/0/0`;

  try {
    return await fetchStoryWithRetry(url,slug);
  } catch (error) {
    console.error('Failed to fetch the story after retries:', error);
    return null; // Handle the error appropriately
  }
};
const fetchItemsHome = async (params) => {
  const url = `${edHost}/api/ed/home/one`;
  const { slug } = params;
  try {
    return await fetchStoryWithRetry(url, slug);
  } catch (error) {
    console.error('Failed to fetch the story after retries:', error);
    return null; // Handle the error appropriately
  }
};



const router = createBrowserRouter([
  {
    element: <App />,
     errorElement: <ErrorPage transition={`${'fadeIn'}`}/>,
    children: [
  {
    path: "/",
    element: <Home transition={`${'fadeIn'}`} slug={'home'} submenu={null}/>,
    loader: fetchItemsHome,
  },
  {
    path: "/news",
    element: <Suspense fallback={<Loader />}><TaxonomyMain nanoid={nanoid()} slug={'news'} tax={11} name={'News'} submenu={'news'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomyMain,
  },
  {
    path: "/scores",
    element: <Suspense fallback={<Loader />}><TaxonomyMainScores nanoid={nanoid()} slug={'scores'} tax={22} name={'Scores'} submenu={'scores'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsScoresMain,
  },

  {
    path: "/reports",
    element: <Suspense fallback={<Loader />}><TaxonomyMain nanoid={nanoid()} slug={'reports'} tax={16} submenu={'reports'} name={'Reports'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomyMain,
  },
  {
    path: "/market",
    element: <Suspense fallback={<Loader />}><TaxonomyMain nanoid={nanoid()} slug={'market'} tax={27} submenu={'market'} name={'Market'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomyMain,
  },
  {
    path: "/auctions",
    element: <Suspense fallback={<Loader />}><TaxonomyMain nanoid={nanoid()} slug={'auctions'} tax={41} submenu={'auctions'} name={'Auctions'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomyMain,
  },
  {
    path: "/para",
    element: <Suspense fallback={<Loader />}><TaxonomyMain nanoid={nanoid()} slug={'para'} tax={130} submenu={'para'} name={'Paralympics'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomyMain,
  },

  {
    path: "/editorials",
    element: <Suspense fallback={<Loader />}><TaxonomyMain nanoid={nanoid()} slug={'editorials'} tax={38} submenu={'editorials'} name={'Editorials'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomyMain,
  },
  {
    path: "/scores/:tax",
    element: <Suspense fallback={<Loader />}><TaxonomyMore nanoid={nanoid()} slug={'scores'} submenu={'scores'} name={'Scores'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomy,
  },


  {
    path: "/news/:tax",
    element: <Suspense fallback={<Loader />}><TaxonomyMore nanoid={nanoid()} slug={'news'} submenu={'news'} name={'News'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomy,
  },
  {
    path: "/reports/:tax",
    element: <Suspense fallback={<Loader />}><TaxonomyMore nanoid={nanoid()} slug={'reports'} submenu={'reports'} name={'Reports'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomy,
  },
  {
    path: "/auctions/:tax",
    element: <Suspense fallback={<Loader />}><TaxonomyMore nanoid={nanoid()} slug={'auctions'} submenu={'auctions'} name={'Auctions'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomy,
  },
  {
    path: "/para/:tax",
    element: <Suspense fallback={<Loader />}><TaxonomyMore nanoid={nanoid()} slug={'para'} submenu={'para'} name={'Paralympics'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomy,
  },
  {
    path: "/market/:tax",
    element: <Suspense fallback={<Loader />}><TaxonomyMore nanoid={nanoid()} slug={'market'} submenu={'market'} name={'Market'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomy,
  },
  {
    path: "/editorials/:tax",
    element: <Suspense fallback={<Loader />}><TaxonomyMore nanoid={nanoid()} slug={'editorials'} submenu={'editorials'} name={'Editorials'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: fetchItemsTaxonomy,
  },
  {
    path: "/:year/:month/:day/:slug",
    element: <Suspense fallback={<Loader />}><SingleStory submenu={null} slug={'story'} transition={`${'fadeIn'}`} /></Suspense>,
    loader: async ({ params }) => {
  const { year, month, day, slug } = params;
  const url = `${edHost}/api/ed/story/${year}/${month}/${day}/${slug}`;

  try {
    return await fetchStoryWithRetry(url,slug);
  } catch (error) {
    console.error('Failed to fetch the story after retries:', error);
    return null; // Handle the error appropriately
  }
},

  },
  {
    path: "/test",
    element: <Suspense fallback={<Loader />}><AddComponent submenu={null} transition={`${'fadeIn'}`} /></Suspense>,
  },
  {
    path: "/search",
    element: <Suspense fallback={<Loader />}><SearchPage submenu={null} transition={`${'fadeIn'}`} /></Suspense>,
  },
  {
    path: "/contact",
    element: <Suspense fallback={<Loader />}><Contact submenu={null} transition={`${'fadeIn'}`} /></Suspense>,
  },
  {
    path: "/:slug",
    element: <Suspense fallback={<Loader />}><Page submenu={null} transition={`${'fadeIn'}`} /></Suspense>,
    loader: async ({ params }) => {
  const { slug } = params;
  const url = `${edHost}/api/ed/page/${slug}`;

  try {
    return await fetchStoryWithRetry(url,slug);
  } catch (error) {
    console.error('Failed to fetch the story after retries:', error);
    return null; // Handle the error appropriately
  }
},
  },
]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <ThemeProvider>
    <RouterProvider router={router} future={{ v7_startTransition: true }}>
    </RouterProvider>
    </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
