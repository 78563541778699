import React,{useState, useRef, useContext, useEffect, Suspense} from 'react'
import { Outlet, NavLink, Link, useLoaderData } from "react-router-dom";
import { ThemeContext } from '../context/ThemeContext';
import EdAdGroup from './EdAdGroup';
import EdBlockGallery from './EdBlockGallery';
import { useDarkMode } from '../customhooks/ThemeHook';
import Loader from "./Loader";
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import equalHeights from './functions/equalHeights';
import getUrlFromTax from './functions/getUrlFromTax';
import gsap from 'gsap'

function getNodes(prop, needle, blnMatch, node){
    var results=[], any=(needle==null);
      node=node||document.documentElement;
      if(node.splice){ node={childNodes:node}; }
    for(var it, i=0, kids=node.childNodes;it=kids[i];i++){
        if(it.childNodes.length){
            results=results.concat(getNodes(prop, needle, blnMatch, it));
        }
        switch(true){
            case    any && it[prop]:
            case    it[prop]===needle:
            case blnMatch && !!String(it[prop]).match(needle):
             results[results.length]=it;
        }
    }
   return results;
}//end getNodes()





function SingleStory(props) {

  const edHost = process.env.REACT_APP_HOST_URL;

  const theme = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useDarkMode(false);
  const selectedWork = useRef();
  let itemsData = useLoaderData();
  let items = [itemsData.ed];
  let nodeId = items[0].id;




  useEffect(() => {
   const manipulateParentP = (parentP) => {
     if (parentP && parentP.firstElementChild) {
       // Check if the class is already added to avoid redundancy
       if (!parentP.firstElementChild.classList.contains('linkFile')) {
         parentP.firstElementChild.classList.add('linkFile');

         // Insert the SVG if it's not already there
         if (!parentP.firstElementChild.querySelector('svg')) {
           parentP.firstElementChild.insertAdjacentHTML(
             'afterbegin',
             '<svg class="w-5 h-4 ml-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
           );
         }

         //parentP.style.backgroundColor = 'yellow'; // Example manipulation
         //console.log('Found and manipulated the <p> tag:', parentP);
       }
     }
   };

   const strongElements = document.querySelectorAll('strong');
   strongElements.forEach((element) => {
     if (element.textContent.includes('Related Links')) {
       const parentP = element.closest('p');
       parentP.classList.add('eDLinkIcon');
       manipulateParentP(parentP);
     }
   });
 }, []);
/*
  useEffect(() => {
      const formatRelatedLinks = () => {
    // Find all <strong> elements
    const strongElements = document.querySelectorAll('strong');

    // Loop through and find the one that contains the text "Related Links"
    strongElements.forEach((element, index) => {
      if(index === 0){
      if (element.textContent.includes('Related Links')) {
        // Traverse upwards to find the first <p> tag

        const parentP = element.closest('p');

        // Manipulate the <p> tag if found
        if (parentP) {
          parentP.classList.add('eDLinkIcon');
          //parentP.style.backgroundColor = '#ededed'; // Example manipulation

            parentP.firstChild.classList.add('linkFile');
          parentP.firstChild.insertAdjacentHTML('afterbegin','<svg class="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>');



          var links = parentP.querySelectorAll('a');
          //Links.forEach(Links.classList.add('relatedLink'));
            links.forEach((element) => element.classList.add('relatedLink'));
        }
      }
      }
    });
      }
        formatRelatedLinks(); // format bottom page links
  },[]);
  */

  useEffect(() => {

    // Function to inject captions
    const injectCaptions = () => {
      // Get all the img elements in the document
      const images = document.querySelectorAll('img[data-caption]');

      // Loop through each image
      images.forEach((img) => {
        // Check if the caption is already injected
        if (!img.nextElementSibling || !img.nextElementSibling.classList.contains('legend')) {
          const captionText = img.getAttribute('data-caption');
          if (captionText) {
            // Create the legend div
            const legendDiv = document.createElement('div');
            legendDiv.className = 'legend';

            // Create the inner div
            const legendInnerDiv = document.createElement('div');
            legendInnerDiv.className = 'legend_inner';
            legendInnerDiv.textContent = captionText;

            // Append the inner div to the legend div
            legendDiv.appendChild(legendInnerDiv);

            // Insert the legend div after the image
            img.insertAdjacentElement('afterend', legendDiv);
          }
        }
      });
    };


    injectCaptions(); // Call the function when the component mounts


  }, []);






  return (
    <Suspense fallback={<Loader />}>
      <div className="flex" style={{background:'#fff',marginTop:'0px',width:'100%',minHeight:'100vh',height:'auto'}}>


<div id="story"  className={"w-screen z-0 p-0 "  + props.transition}>

  <div className="grid grid-cols-1">
  {items.length > 0 ? items.map((item,index) => (


      <div key={index} className="flex flex-col items-center p-0 space-y-3 text-left">

      {item.image.filename !== 'NULL' ?
      <picture id={'pict-' + index} className="headImage">

      <ProgressiveImage
      src={'https://eurodressage.com'+item.image.url}
      placeholder={'/static/trans.gif'}
      modal={theme.modal}
      bg={'#333'}
      imgMargin={props.imgMargin}
      imgWidth={100}
      />
      </picture>
      :
      ''}

      <div className="flex flex-col items-center p-6 space-y-3 text-left">
          <span className="date mb-0">{item.date}</span>
          <p>
            <a className="edTextGradient" href={ getUrlFromTax(item.taxParent[0]['label']) }>
              { item.taxParent[0]['label'] }</a>
              &nbsp;&gt;&nbsp;
              <a className="edTextGradient" href={ getUrlFromTax(item.taxParent[1]['label']) }>
                {item.taxParent[1]['label']}
            </a>
          </p>

          <h1 className="storyTitle text-2xl text-center font-medium pb-0">{item.title}</h1>
          <p className="headline text-medium pt-0 text-center">{item.headline}</p>
          <div className="text-base text-gray-900" dangerouslySetInnerHTML={{__html: item.body }}/>


      </div>

      <div className="text-center pt-1 pb-2">
      <EdBlockGallery imgMargin={0} url={`${edHost}/rest/ed/story/gallery/${nodeId}`} slug={nodeId} name="Gallery"/>
      </div>


      </div>


              )) : ''}
</div>
</div>



</div>

<section>
  <div className="text-center pt-1 pb-2">
  <EdAdGroup url={`${edHost}/rest/ed/ad/regular`} name=""/>
</div>
</section>

</Suspense>
  )
}

export default SingleStory
