import React,{ useState, useRef, useEffect, useContext,memo } from 'react'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from '../context/ThemeContext';
import NavbarSub from './NavbarSub'


const Navbar = memo((props) => {

  const location = useLocation();

  const _loc = location.pathname.split('/');
  const _pathnameCheck = _loc[_loc.length - 1];
  const theme = useContext(ThemeContext);
  const navRef = useRef();

  const scoresRef = useRef();
  const newsRef = useRef();
  const reportsRef = useRef();
  const auctionsRef = useRef();
  const paraRef = useRef();
  const marketRef = useRef();
  const editorialsRef = useRef();

  const [scrollPosition, setScrollPosition] = useState(0);
  const  [navCondition,setNavCondition] = useState(false);
  const [burgerClass,setBurgerClass] = useState('');
  const [subnavId, setSubnavId] = useState('');

  const [subnavOpen,setSubnavOpen] = useState(false);

  function toggleNav(condition, burger){
    setSubnavOpen(false);
    navCondition === true ? setNavCondition(false) : setNavCondition(true);
    burger === true ? setBurgerClass('tham-active') : setBurgerClass('')
  }
  function toggleSubnav(e){
    e.preventDefault();
    subnavOpen === false ? setSubnavOpen(true) : setSubnavOpen(false);
    var _subnavId = e.currentTarget.dataset.id;

    var _submenus = document.querySelectorAll('ul');
    for (var i = 0; i < _submenus.length; ++i) {
      _submenus[i].classList.remove('down');
      _submenus[i].parentNode.classList.remove('down');
    }

    if(_subnavId === 'scores'){
    scoresRef.current.classList.toggle('down');
    scoresRef.current.parentNode.classList.toggle('down');
    }
    if(_subnavId === 'news'){
    newsRef.current.classList.toggle('down');
    newsRef.current.parentNode.classList.toggle('down');
    }
    if(_subnavId === 'reports'){
    reportsRef.current.classList.toggle('down');
    reportsRef.current.parentNode.classList.toggle('down');
    }
    if(_subnavId === 'auctions'){
    auctionsRef.current.classList.toggle('down');
    auctionsRef.current.parentNode.classList.toggle('down');
    }
    if(_subnavId === 'para'){
    paraRef.current.classList.toggle('down');
    paraRef.current.parentNode.classList.toggle('down');
    }
    if(_subnavId === 'market'){
    marketRef.current.classList.toggle('down');
    marketRef.current.parentNode.classList.toggle('down');
    }
    if(_subnavId === 'editorials'){
    editorialsRef.current.classList.toggle('down');
    editorialsRef.current.parentNode.classList.toggle('down');
    }


  }
    useEffect(() => {
      //console.log(theme.state);
    },[theme])

  useEffect(() => {
   const handleScroll = () => {
     const currentScrollPos = window.pageYOffset;
     setScrollPosition(currentScrollPos);
   };

   window.addEventListener('scroll', handleScroll);

   // Cleanup the event listener when the component unmounts
   return () => {
     window.removeEventListener('scroll', handleScroll);
   };
 }, []);


  return (
    <div
          className={`fixed top-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            scrollPosition > 100 ? '-translate-y-12 h-full' : 'translate-y-0'
          }`}
          style={{ zIndex: 900, height: '80px' }}
        >
    <nav style={{width:'100%', height:'auto'}} className={"fixed left-0 top-0 flex px-6 border-b-white items-center open-" + navCondition}>

        <div style={{zIndex:900}} className="flex text-lg font-bold md:py-0 py-0 z-600">
          <a title="home" href="/">  <img width="290" height="40" className="logoIcon" src="/img/eurodressage-logo-gradient.svg" alt=""/></a>
        </div>

        <ul ref={navRef} className={ 'nav nav-' + navCondition +" md:px-2 ml-auto md:flex md:space-x-2 absolute md:relative top-full left-0 right-0 z-200"}>

            <li>
              <a onClick={() => toggleNav(false, false) } className="block text-xl pl-5 p-2 text-white uppercase tracking-wide" href="/">
              Home
              </a>
            </li>
            <li>
              <a onClick={() => toggleNav(false, false) } className="block text-xl pl-5 p-2 text-white uppercase tracking-wide" href="/scores">
               Scores
            </a>
            </li>

            <li>
              <a data-id="news" onClick={(e) => toggleSubnav(e)} className="block text-xl pl-5 p-2 text-white uppercase tracking-wide" href="/news/dressage">
              News <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className= {'inline w-5 mt-[-3px] arrow'}><path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd"></path></svg>
                </a>
              <ul ref={newsRef} className={'subnav'}>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/news/dressage">
                  Dressage
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/news/what-is-happening">
                  What is happening
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase" href="/news/focus">
                  Focus
                  </a>
              </li>
              <li>
                <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase" href="/news/breeding">
                Breeding
                </a>
            </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left mb-2 uppercase" href="/news/veterinary">
                  Veterinary
                  </a>
              </li>

            </ul>

            </li>

            <li>
            <a data-id="reports" onClick={toggleSubnav} className="block text-xl pl-5 p-2 text-white uppercase tracking-wide" href="/reports/shows">
            Reports <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className= {'inline w-5 mt-[-3px] arrow'}><path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd"></path></svg>
              </a>
            <ul ref={reportsRef} className={'subnav'}>
              <li>
                <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/reports/shows">
                Shows
              </a>
              </li>
              <li>
                <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/reports/training">
                Training
              </a>
              </li>
              <li>
                <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase" href="/reports/walks">
                Walks
                </a>
            </li>
            <li>
              <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase" href="/reports/interviews">
              Interviews
              </a>
          </li>
              <li>
                <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left mb-2 uppercase" href="/reports/history">
                History
                </a>
            </li>

          </ul>

          </li>


            <li>
              <a data-id="auctions" onClick={toggleSubnav} className="block text-xl pl-5 p-2 text-white uppercase tracking-wide" href="/reports/shows">
              Auctions <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className= {'inline w-5 mt-[-3px] arrow'}><path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd"></path></svg>
                </a>
              <ul ref={auctionsRef} className={'subnav'}>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/psi">
                  PSI
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/vechta">
                  Oldenburg
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/verden">
                  Hanoverian
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/westfalian">
                  Westfalian
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/van-olst-sales">
                  Van Olst Sales
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/excellent-dressage-sales">
                  Excellent Dressage Sales
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/netherlands">
                  The Netherlands
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/germany">
                  Germany
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/scandinavia">
                  Scandinavia
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/miscellaneous">
                  Miscellaneous
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/brightwells">
                  Brightwells
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/klosterhof-medingen">
                  Klosterhof Medingen
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/auctions/equine-elite">
                 Equine Elite
                </a>
                </li>

            </ul>
            </li>

            <li>
              <a data-id="para" onClick={toggleSubnav} className="block text-xl pl-5 p-2 text-white uppercase tracking-wide" href="/para/news">
              Paralympics <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className= {'inline w-5 mt-[-3px] arrow'}><path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd"></path></svg>
                </a>
              <ul ref={paraRef} className={'subnav'}>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/para/news">
                  News
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/para/show-reports">
                  Show Reports
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/para/blogs">
                  Blogs
                </a>
                </li>
            </ul>
            </li>

            <li>
              <a data-id="market" onClick={toggleSubnav} className="block text-xl pl-5 p-2 text-white uppercase tracking-wide" href="/market">
              Market <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className= {'inline w-5 mt-[-3px] arrow'}><path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd"></path></svg>
                </a>
              <ul ref={marketRef} className={'subnav'}>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/market/equimarket">
                  Equimarket
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/market/young-stock">
                  Young stock
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/market/stallion-market">
                  Stallion market
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/market/grooms-corner">
                  Job Offers
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/market/real-estate">
                  Real Estate
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/market/photography">
                  Photography
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/market/events">
                  Events
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/market/books-dvd">
                  Books - DVD
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/market/products">
                  Products
                </a>
                </li>
            </ul>
            </li>


            <li>
              <a data-id="editorials" onClick={toggleSubnav} className="block text-xl pl-5 p-2 text-white uppercase tracking-wide" href="/market">
              Editorials <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" className= {'inline w-5 mt-[-3px] arrow'}><path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd"></path></svg>
                </a>
              <ul ref={editorialsRef} className={'subnav'}>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/editorials/eurodressage-editorials">
                  Editorials
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/editorials/guest-columnists">
                  Guest colummists
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/editorials/david-stickland">
                  David Stickland
                </a>
                </li>
                <li>
                  <a onClick={() => toggleNav(false, false) } className="sublink block text-xl pl-8 pt-1 pb-1 text-white text-left uppercase tracking-wide" href="/editorials/wayne-s-world">
                  Wayne's world
                </a>
                </li>
            </ul>
            </li>

            <li>
              <a onClick={() => toggleNav(false, false) } className="block text-xl pl-5 p-2 text-white uppercase tracking-wide" href="/contact-mobile">
              Contact
              </a>
            </li>

            <li>

              <a onClick={() => toggleNav(false, false) } className="block pt-8 pl-5 p-2 text-white text-sm uppercase tracking-wide" href="/general-terms-of-use">
              General Terms of Use
              </a>
            </li>
            <li>
              <a onClick={() => toggleNav(false, false) } className="block pl-5 p-2 text-white text-sm uppercase tracking-wide" href="/cookie-policy">
              Cookie policy
              </a>
            </li>
            <li>
              <a onClick={() => toggleNav(false, false) } className="block pl-5 p-2 text-white text-sm uppercase tracking-wide" href="/privacy-data-security">
              Privacy - Data Security
              </a>
            </li>


        </ul>
        <div style={{zIndex:991}} className="ml-auto md:hidden text-grey cursor-pointer">
          <a aria-label="menu" onClick={() => navCondition === true ? toggleNav(true,false) : toggleNav(false,true) } href="#nav">
            <div className={'tham tham-e-squeeze tham-w-4 ' + burgerClass}>
              <div className="tham-box">
                <div className="tham-inner bg-white" />
              </div>
            </div>
          </a>
      </div>
    </nav>


    <NavbarSub slug={props.submenu} pathnameCheck={_pathnameCheck}/>

  {/* <div style={{zIndex:9}} className="bg-white-transparent fixed border-b border-b-grey-500 mt-[50px] w-full inline-flex flex-nowrap overflow-scroll pt-1 pb-1">
      <ul className="pt-2 pb-2 pb-1 flex items-center justify-center md:justify-start [&_li]:max-w-none [&_p]:max-w-none">

         <li>
           <a className="p-1 pt-2" href="/news">
             <span className=" inline p-2 pl-2 ml-1 ">
               News
             </span>
           </a>
         </li>

         <li>
           <a className="p-1 pt-2" href="/scores/2024/">
             <svg className="w-5 inline ml-1" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
              viewBox="0 0 60 90" style={{enableBackground:'new 0 0 60 90'}}>
           <g>
             <path fill="#fff" className="st0" d="M21.8,42.7c2,0,3.4,0.6,4.7,2.1c1.5,1.8,4.5,2,6,0.3c1.6-1.9,3.5-2.4,5.9-2.4c2.5,0,4.3-2.4,4-4.9c-0.2-1.7,0.4-3.1,1.6-4.2c2.6-2.3,2.5-4.7,0-7.3c-1-1-1.4-2.5-2.1-3.8c0.6-3.3-1-5.3-4.1-5.2c-2,0.1-3.6-0.6-4.9-2.1c-1.7-2-4.5-2.1-6.1-0.2c-1.5,1.8-3.3,2.4-5.5,2.4c-2.6-0.1-4.6,2.1-4.3,4.7c0.2,1.9-0.4,3.3-1.8,4.4c-2.3,1.9-2.2,4.9,0.1,6.9c1.2,1,2,2.3,1.8,3.9C16.7,40.7,18.5,42.7,21.8,42.7z M27.4,27.3l2.1-6.6l2.1,6.6h6.9L33,31.4l2.1,6.6l-5.6-4.1l-5.6,4.1l2.1-6.6l-5.6-4.1H27.4z"/>
             <path fill="#bc0c0c" d="M9.5,80.7c1.4-3.5,9.1-22.8,10.5-26.4c0.6-1.5,1.1-3.1,1.8-4.5c0.2-0.5,1-1.1,1.3-1c1.6,0.4,2.7,2.8,2.1,4.4c-1.6,4-7.3,18.2-10.4,26.2l5.4-1.7c3.4-8.6,7.7-19.3,9.2-23.2c0.2,0,0.3,0,0.5,0c1.5,3.8,6.2,15,9.9,24l5.6,1.8c-3.2-7.9-9.5-23.3-11-26.8c-0.7-1.7-0.5-2.8,0.8-4c1.4-1.4,2-1.3,2.7,0.5c2,4.9,10.2,24.8,13.2,32.2l5.5,1.8c-0.1-0.5-0.4-1-0.6-1.5C53.9,77.6,44.5,55,42.4,50c-0.5-1.2-0.6-2,1-2.5c3-1,4.4-3.5,4.1-6.6c-0.2-2.3,0.5-4.2,2.3-5.8c3.6-3.2,3.6-7-0.1-10.2c-1.5-1.3-2.4-3-2.2-5.1c0.4-5.1-1.9-7.6-7-7.5c-2.5,0.1-4.4-0.8-6-2.7c-2.4-3-6.8-3.2-9.2-0.3c-1.9,2.3-4.1,3.1-6.9,3c-4.3-0.2-6.8,2.8-6.4,7c0.2,2.1-0.4,4-2,5.4C6,28,5.9,31.6,9.7,35.1c1.8,1.6,2.5,3.6,2.2,6c-0.1,0.9,0,1.8,0.3,2.6c0.8,2.5,2.8,3.6,5.4,4.4C15.1,54.5,6.3,76.5,3.8,82.7c0,0,0,0,0.1,0l5.4-1.6C9.3,81,9.4,80.8,9.5,80.7zM17,37.4c0.2-1.6-0.6-2.9-1.8-3.9c-2.3-2-2.3-5-0.1-6.9c1.4-1.2,2-2.6,1.8-4.4c-0.3-2.6,1.6-4.8,4.3-4.7c2.3,0,4-0.6,5.5-2.4c1.6-1.9,4.4-1.7,6.1,0.2c1.3,1.6,2.9,2.2,4.9,2.1c3.1-0.1,4.7,1.8,4.1,5.2c0.7,1.3,1.1,2.8,2.1,3.8c2.5,2.6,2.6,5,0,7.3c-1.2,1.1-1.8,2.4-1.6,4.2c0.3,2.5-1.5,4.9-4,4.9c-2.4,0-4.3,0.5-5.9,2.4c-1.5,1.7-4.6,1.5-6-0.3c-1.3-1.5-2.8-2.2-4.7-2.1C18.5,42.7,16.7,40.7,17,37.4z"/>
             <polygon fill="#bc0c0c" points="22.8,39.7 29.5,34.8 36.2,39.7 33.6,31.8 40.4,26.9 32.1,26.9 29.5,19 26.9,26.9 18.7,26.9 25.4,31.8 	"/>
           </g>
           </svg>
           </a>
         </li>
         <li>

           <a className="p-1 pt-2" href="/scores/2024">
              <span className=" inline p-1 ml-0">Scores</span>
           </a>
         </li>

         <li>
           <a className="p-1 pt-2 " href="/reports">
             <span className=" inline p-2 pl-1 ml-1 ">Reports</span>
             </a>
         </li>
         <li>
           <a className="p-1 pt-2" href="/market">
             <span className=" inline p-2 pl-1 ">Market</span>
             </a>
         </li>

     </ul>

   </div>
     */}
 </div>

  )
});

export default Navbar
