import React,{useState, useEffect, useContext, memo} from 'react';
import { NavLink, Link, useLoaderData, useParams, useLocation} from "react-router-dom";
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import { ThemeContext } from '../context/ThemeContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Pagination} from 'swiper/modules';
import he from 'he';
import { nanoid } from "nanoid";

import equalHeights from './functions/equalHeights';
import fetchStoryWithRetry from './functions/fetchStoryWithRetry';
import getFromTax from './functions/getFromTax'

import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';



const EdBlock = memo( (props) => {

  const edHost = process.env.REACT_APP_HOST_URL;

  var nanoId = nanoid();

  const url = props.url;
  const slug = props.slug;
  //console.log('_tax_',tax);
  const location = useLocation();

  const [dataBlock,setDataBlock] = useState();
  const [offsetItems, setOffsetItems] = useState(0);
  const [dateOffset, setDateOffset] = useState();

  var storageDate = new Date().getTime();
  var cacheExpire = 15; //expiry of cache in minutes


  const loadDataToBlock = async () => {

    const _url = url;

    const _local_data = JSON.parse(localStorage.getItem('block-' + slug));
    const _local_storageDate = JSON.parse(localStorage.getItem('d_block-' + slug ));
    var _now = new Date().getTime();
    var diffInMin = (_now - _local_storageDate)/1000/60;

    if(_local_data && diffInMin < cacheExpire ){

      setDataBlock(null);
      setDateOffset(null);
      setDataBlock(_local_data);
      var _dateOffset = _local_data[0].date;
      setDateOffset(_dateOffset);

      console.log('Reading from cache for ' + 'block-' + slug + ' ' + diffInMin +' min');
      return;

    }else{

    try {
      const data = await fetchStoryWithRetry(_url,props.slug);
        setDataBlock(null);
        setDateOffset(null);
        setDataBlock(data.ed);
        var _dateOffset = data.ed[0].date;
        setDateOffset(_dateOffset);

        window.localStorage.setItem('block-' + slug,JSON.stringify(data.ed));
        window.localStorage.setItem('d_block-' + slug,storageDate);

        console.log('Setting cache for ' + 'block-' + slug );

    } catch (error) {
      console.error('Failed to fetch the story after retries:', error);
      return null; // Handle the error appropriately
    }

    }

  }

  const theme = useContext(ThemeContext);

  useEffect(() => {
  //console.log(location);

  loadDataToBlock();
    },[location]);


useEffect((props) => {
  var t = setTimeout(function(){
    equalHeights(nanoId);

    clearTimeout(t);
  },800);

},[dataBlock]);

  return (
    <div className={props.transition}>

<section style={{overflow:'hidden'}} className={" fadeIn w-100 pt-0 p-2 " + props.transition}>
  <div className="innerSection">
 <p className="text-medium text-left tracking-wide pl-2 pb-2"><strong>{props.name}</strong> <svg className="w-4 h-4 ml-1 inline edTextGradient" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>

  </p>
  <Swiper
        className=" rounded-xl flex swiperHalf"
        modules={[Virtual, Pagination]}
        spaceBetween={4}
        //slidesPerView={theme.state.width < 700 ? 1 : 3 }
        slidesPerView={2}
        virtual={true}
        //cache={true}
        resistanceRatio={0.3}
        speed={800}
        //addSlidesAfter={4}
        //addSlidesBefore={4}
        loop={false}
        pagination={true}
        onSlideChange={() => equalHeights('equalHeight_1')}
        //onSwiper={(swiper) => equalHeights('equalHeight') }
      >
        {dataBlock ? dataBlock.map((item,index) => (


        <SwiperSlide key={'tax_2-'+ index} virtualIndex={index}>
          <div style={{}} className="items-center text-center">

          <NavLink onClick={(e)=> {e.currentTarget.classList.add('selected');console.log(e)}} className="edStory glow rounded-xl overflow-hidden" to={item.url} state={{ from: null }}>

          <picture style={{background:'#ddd', display:'block',overflow:'hidden', minHeight: 'calc(48vw * (538 / 680))'}} id={'btn-' + index} className="">
          <ProgressiveImage
          src={edHost + item.image_thumb}
          placeholder={'/static/trans.gif'}
          modal={theme.modal}
          bg={'#333'}
          width={680}
          height={538}
          imgMargin={props.imgMargin}
          imgWidth={50}
          />
          <div className="_overlay"></div>
          </picture>

          <div className={nanoId + "equalHeight_1 rounded-b-xl text-left bg-magenta dark:bg-magenta pb-2"}>

              <p className="p-2 text-base text-left storyTitle-sm ls-small clampStoryLinesmall">{he.decode(item.title)}</p>
        {/*      <p className="pl-2 headline-sm pb-0 ls-small">{item.date}</p>
              <p className="pl-2 edTextGradient headline-sm leading-1 ls-small pb-3">{he.decode(item.headline)}
            </p>
            */}

          </div>

        </NavLink>
          </div>
          <p>&nbsp;</p>
      </SwiperSlide>

              ))  : ''}
       </Swiper>
</div>
</section>

</div>
  )
});

export default EdBlock
