import React,{useState, useRef, useContext, useEffect, Suspense} from 'react'
import { Outlet, NavLink, Link, useLoaderData } from "react-router-dom";
import { ThemeContext } from '../context/ThemeContext';
import EdAdGroup from './EdAdGroup';
import EdBlockGallery from './EdBlockGallery';
import { useDarkMode } from '../customhooks/ThemeHook';
import Loader from "./Loader";
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import equalHeights from './functions/equalHeights';
import getUrlFromTax from './functions/getUrlFromTax';
import gsap from 'gsap'



function Page(props) {

  const edHost = process.env.REACT_APP_HOST_URL;

  const theme = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useDarkMode(false);
  const selectedWork = useRef();
  let itemsData = useLoaderData();
  let items = [itemsData.ed];
  let nodeId = items[0].id;





  return (
    <Suspense fallback={<Loader />}>
      <div className="flex" style={{background:'#fff',marginTop:'0px',width:'100%',minHeight:'100vh',height:'auto'}}>


<div id="story"  className={"w-screen z-0 p-0 "  + props.transition}>

  <div className="grid grid-cols-1">
  {items.length > 0 ? items.map((item,index) => (


      <div key={index} className="flex flex-col items-center p-0 space-y-3 text-left">



      <div className="flex flex-col items-center p-6 pt-12 space-y-3 text-left">
      {/*    <span className="date mb-0">{item.date}</span> */}


          <h1 className="storyTitle text-2xl text-center font-medium pb-0">{item.title}</h1>
          <div className="text-base text-gray-900" dangerouslySetInnerHTML={{__html: item.body }}/>


      </div>

      </div>


              )) : ''}
</div>
</div>



</div>

<section>
  <div className="text-center pt-1 pb-2">
  <EdAdGroup url={`${edHost}/rest/ed/ad/regular`} name=""/>
</div>
</section>

</Suspense>
  )
}

export default Page
