import React,{useState, useEffect, useContext, memo} from 'react';
import { NavLink, Link, useLoaderData, useParams, useLocation} from "react-router-dom";
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import { ThemeContext } from '../context/ThemeContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Pagination} from 'swiper/modules';
import he from 'he';
import { nanoid } from "nanoid";

import equalHeights from './functions/equalHeights';
import fetchStoryWithRetry from './functions/fetchStoryWithRetry';
import getFromTax from './functions/getFromTax'

import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';



const EdBlockGallery = memo( (props) => {

  const edHost = process.env.REACT_APP_HOST_URL;

  var nanoId = nanoid();

  const url = props.url;
  const slug = props.slug;
  //console.log('_tax_',tax);
  const location = useLocation();

  const [dataBlock,setDataBlock] = useState();
  const [offsetItems, setOffsetItems] = useState(0);
  const [dateOffset, setDateOffset] = useState();

  var storageDate = new Date().getTime();
  var cacheExpire = 15; //expiry of cache in minutes


  const loadDataToBlock = async () => {

    const _url = url;

    const _local_data = JSON.parse(localStorage.getItem('block-' + slug));
    const _local_storageDate = JSON.parse(localStorage.getItem('d_block-' + slug ));
    var _now = new Date().getTime();
    var diffInMin = (_now - _local_storageDate)/1000/60;

    if(_local_data && diffInMin < cacheExpire ){

      setDataBlock(null);
      setDateOffset(null);
      setDataBlock(_local_data);
      var _dateOffset = _local_data[0].date;
      setDateOffset(_dateOffset);

      console.log('Reading from cache for ' + 'block-' + url + ' ' + diffInMin +' min');
      return;

    }else{

    try {
      const data = await fetchStoryWithRetry(_url,props.slug);
        setDataBlock(null);
        setDateOffset(null);
        setDataBlock(data);
        var _dateOffset = _now;
        setDateOffset(_dateOffset);

        //console.log(data);

        window.localStorage.setItem('block-' + slug,JSON.stringify(data));
        window.localStorage.setItem('d_block-' + slug,storageDate);

        console.log('Setting cache for ' + 'block-' + slug );

    } catch (error) {
      console.error('Failed to fetch the story after retries:', error);
      return null; // Handle the error appropriately
    }

    }

  }

  const theme = useContext(ThemeContext);

  useEffect(() => {
  //console.log(location);

  loadDataToBlock();
    },[location]);


useEffect((props) => {
  var t = setTimeout(function(){
    equalHeights(nanoId);

    clearTimeout(t);
  },800);

},[dataBlock]);

  return (
    <div className={props.transition}>

<section style={{overflow:'hidden'}} className={" fadeIn w-100 pt-0 p-2 " + props.transition}>
  <div className="innerSection">
 {/*
 <p className="text-medium text-left tracking-wide pl-2 pb-2"><strong>{props.name}</strong> <svg className="w-4 h-4 ml-1 inline edTextGradient" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>

  </p>*/}

        {dataBlock ? dataBlock.map((item,index) => (

        <div className="m-1 mb-2">
        <picture style={{background:'#fff', display:'block',overflow:'hidden', minHeight: 'calc(98vw * (538 / 680))'}} id={'btn-' + index} className="">
          <ProgressiveImage
          src={edHost + item.image_thumb}
          placeholder={'/static/trans.gif'}
          modal={theme.modal}
          bg={'#fff'}
          width={680}
          height={538}
          imgMargin={props.imgMargin}
          imgWidth={100}
          />
          <div className="_overlay"></div>
          </picture>
          <p className="p-2 pt-1 text-sm">{item.desc.replaceAll('&#039;',"'")}</p>
        </div>




              ))  : ''}

</div>
</section>

</div>
  )
});

export default EdBlockGallery
