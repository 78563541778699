import React from 'react'
import SearchStory from './SearchStory';
function SearchPage(props) {
  return (
    <section className="p-2 pt-36">
    <SearchStory/>
</section>
  )
}

export default SearchPage
